<script setup lang="ts">
import type { SimpleTableColumn, SimpleTableData } from './types'
import type { SimpleTableVariant } from './style'
import {
  createHeaderSlotProps,
  createItemSlotProps,
  getTemplate,
} from './utils'

const props = defineProps<{
  columns: SimpleTableColumn[]
  data: SimpleTableData
  variant: SimpleTableVariant
}>()

const emit = defineEmits<{
  'click:row': [value: any]
}>()

const data = computed(() =>
  Array.isArray(props.data) ? props.data : [props.data],
)
</script>

<template>
  <table :class="variant.table()">
    <!-- Header -->
    <tr :class="variant.theadTr()">
      <th v-for="column in columns" :key="column.id" :class="variant.theadTh()">
        <slot
          :name="`header-${column.id}`"
          v-bind="createHeaderSlotProps(column)"
        >
          <slot name="header" v-bind="createHeaderSlotProps(column)">
            {{ column.header }}
          </slot>
        </slot>
      </th>
    </tr>
    <tr
      v-for="(item, idx) in data"
      :key="idx"
      :class="variant.tbodyTr()"
      @click="emit('click:row', item)"
    >
      <td v-for="column in columns" :key="column.id" :class="variant.tbodyTd()">
        <slot
          :name="`item-${column.id}`"
          v-bind="createItemSlotProps(column, item)"
        >
          <slot name="item" v-bind="createItemSlotProps(column, item)">
            <span>{{ getTemplate(column, item) }}</span>
          </slot>
        </slot>
      </td>
    </tr>
  </table>
</template>
